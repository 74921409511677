<template>
	<div class="formulaire__jira">
		<div>
			<router-link to="/" class="back-menu">
				&lt; Retour aux outils
			</router-link>			
		</div>
		<h2 class="mt-3">Historique de mes demandes</h2>
		<div class="containeur_demandes mt-8">
			<v-btn class="mb-8" @click="filter_open=!filter_open">
				<span v-if="!filter_open">Afficher les filtres</span>
				<span v-else>Masquer les filtres</span>
			</v-btn>
			<v-dialog 
				v-model="fields_panel_open" 
				fullscreen hide-overlay 
				transition="dialog-bottom-transition"
			>
				<template v-slot:activator="{ on, attrs }" class="mt-0">
					<v-btn v-bind="attrs" v-on="on" class="mb-8 ml-4">Options du tableau</v-btn>
				</template>
				<v-card>
					<v-toolbar dark color="primary">
						<v-btn icon dark @click="fields_panel_open = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
						<v-toolbar-title>Options du tableau</v-toolbar-title>
					</v-toolbar>
					<v-card-text>
						<OptionsJiraTab 
							:headers_dispo="header_dispo" 
							:headers="headers"
							@change="changeOptionJira"
						></OptionsJiraTab>
					</v-card-text>
				</v-card>
			</v-dialog>
			<v-data-table
				:headers="headers"
				:items="demandes"
				:options.sync="options"
				:server-items-length="total"
				:loading="loading"
				:sort-by="['created']"
				:footer-props="{
					itemsPerPageOptions: [10,20,30]
				}"
				class="elevation-1"
				hide-default-header
			>
				<template v-slot:header="{ props }">
					<thead class="v-data-table-header">
						<tr>
							<th role="columnheader" v-for="head in props.headers" v-bind:key="head.id" style="text-align:left;cursor: pointer;" class="text-start">
								<span>{{ head.text }}</span>
							</th>
						</tr>
						<tr v-if="filter_open">
							<th v-for="head in props.headers" v-bind:key="head.id" class="text-start input-filter">
								<form autocomplete="off">
									<div v-if="head.value == 'fields.customfield_10055'">
										<v-text-field
											dense
											filled
											class="mb-0 pb-0 mt-0 pt-0"
											placeholder="Rechercher un cab"
											v-model="cab"
											clearable
											style="font-size: 12px;font-weight: normal"
										></v-text-field>
									</div>
									<div v-if="head.value == 'fields.customfield_10066'">
										<v-text-field
											dense
											filled
											class="mb-0 pb-0 mt-0 pt-0"
											placeholder="Rechercher une MF"
											v-model="mf"
											clearable
											style="font-size: 12px;font-weight: normal"
										></v-text-field>
									</div>
									<div v-if="head.value == 'key'">
										<v-text-field
											dense
											filled
											class="mb-0 pb-0 mt-0 pt-0"
											placeholder="Rechercher une ref"
											v-model="key"
											clearable
											style="font-size: 12px;font-weight: normal"
										></v-text-field>
									</div>
									<div v-if="head.value == 'fields.summary'">
										<v-text-field
											dense
											filled
											class="mb-0 pb-0 mt-0 pt-0"
											placeholder="Rechercher dans le titre"
											v-model="summary"
											clearable
											style="font-size: 12px;font-weight: normal"
										></v-text-field>
									</div>
									<div v-if="head.value == 'renderedFields.created'">
										<InputIntervalDate
											@change="IntervalDateChanged"
										></InputIntervalDate>
									</div>
									<div v-if="head.value == 'fields.priority.name'">
										<v-select
											dense
											filled
											class="mb-0 pb-0 mt-0 pt-0"
											placeholder="Toutes les priorités"
											v-model="filters.priority"
											:items="filters_opt.priority"
											item-text="label"
											item-value="id"
											clearable
											style="font-size: 12px;font-weight: normal"
										></v-select>
									</div>
									<div v-if="head.value == 'fields.status.name'">
										<v-select
											dense
											filled
											class="mb-0 pb-0 mt-0 pt-0"
											placeholder="Tout les status"
											v-model="filters.status"
											:items="filters_opt.status"
											item-text="label"
											item-value="id"
											clearable
											style="font-size: 12px;font-weight: normal"
										></v-select>
									</div>
									<div v-if="head.value == 'fields.reporter.displayName'">
										<v-autocomplete
											dense
											filled
											class="mb-0 pb-0 mt-0 pt-0"
											placeholder="Rechercher un rapporteur"
											v-model="filters.reporter"
											:items="filters_opt.reporter"
											item-text="label"
											item-value="id"
											clearable
											style="font-size: 12px;font-weight: normal"
											autocomplete="off"
										></v-autocomplete>
									</div>
								</form>
							</th>
						</tr>
					</thead>
				</template>
				<template v-slot:item.id="{ item }">
					<span class="field-mineur">{{ item.id }}</span>
				</template>
				<template v-slot:item.renderedFields.created="{ item }">
					<span class="field-mineur">{{ item.renderedFields.created }}</span>
				</template>
				<template v-slot:item.fields.priority.name="{ item }">
					<img :src='item.fields.priority.iconUrl' style="width:16px;padding-top: 5px;">
					{{ item.fields.priority.name }}
				</template>
				<template v-slot:item.fields.summary="{ item }">
					<router-link :to="{ name: 'jira-suivi-detail', params: { ref: item.key }}">
						<span>{{ item.fields.summary }}</span>
					</router-link>
				</template>
				<template v-slot:item.fields.status.name="{ item }">
					<v-chip label small dark :color="getColor(item.fields.status.name)">
						{{ item.fields.status.name }}
					</v-chip>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapMutations } from 'vuex';
	import InputIntervalDate from '../InputIntervalDate.vue'
	import OptionsJiraTab from '../OptionsJiraTab.vue'
	

	export default {
		name: 'Jira',
		components : {
			InputIntervalDate,
			OptionsJiraTab
		},
		data: () => ({
			myArray: [],
			demandes: [],
			total: 0,
			loading: false,
			options: {},
			filter_open: false,
			header_dispo: [
				{ text: 'Ref', value: 'key', sortable: false },
				{ text: 'Maison financement', value: 'fields.customfield_10066', sortable: false },
				{ text: 'Cab', value: 'fields.customfield_10055', sortable: false },
				{ text: 'Nom Client', value: 'fields.customfield_10078', sortable: false },
				{ text: 'Site', value: 'fields.customfield_10077', sortable: false },
				{ text: 'Titre', value: 'fields.summary', sortable: false },
				{ text: 'Crée le', value: 'renderedFields.created', sortable: false },
				{ text: 'Rapporteur', value: 'fields.reporter.displayName', sortable: false },
				{ text: 'Status', value: 'fields.status.name', sortable: false },
				{ text: 'Priority', value: 'fields.priority.name', sortable: false }
			],
			headers: [
				{ text: 'Ref', value: 'key', sortable: false },
				{ text: 'Cab', value: 'fields.customfield_10055', sortable: false },
				{ text: 'Nom Client', value: 'fields.customfield_10078', sortable: false },
				{ text: 'Site', value: 'fields.customfield_10077', sortable: false },
				{ text: 'Titre', value: 'fields.summary', sortable: false },
				{ text: 'Crée le', value: 'renderedFields.created', sortable: false },
				{ text: 'Rapporteur', value: 'fields.reporter.displayName', sortable: false },
				{ text: 'Maison financement', value: 'fields.customfield_10066', sortable: false },
				{ text: 'Status', value: 'fields.status.name', sortable: false }				
			],
			filters: {
				key: "",
				summary: "",
				created: [],
				status: "",
				priority: "",
				reporter: "",
				cab: "",
				mf: "",
			},
			filters_opt: {
				status: [
					{id: "Ouvert", label: "Ouvert"},
					{id: "Termine", label: "Termine"},
					{id: "En cours", label: "En cours"},
					{id: "En attente", label: "En attente"},
					{id: "Rouvert", label: "Rouvert"}
				],
				priority: [
					{id: "all", label: "Tout"},
					{id: "medium", label: "Medium"},
					{id: "highest", label: "Highest"},
				],
				reporter: []
			},
			fields_panel_open : false
		}),
		created: function() {
			var headerStorage = localStorage.getItem("jira-data-columns")
			if(headerStorage != null) {
				this.headers = JSON.parse(headerStorage)
			}
		},
		computed: {
			mf: {
				get() {
					return this.filters.mf;
				},
				set(val) {
					val = (val == null) ? "" : val;
					this.filters.mf = val;
				}
			},
			cab: {
				get() {
					return this.filters.cab;
				},
				set(val) {
					val = (val == null) ? "" : val;
					this.filters.cab = val;
				}
			},
			summary: {
				get() {
					return this.filters.summary;
				},
				set(val) {
					val = (val == null) ? "" : val;
					this.filters.summary = val;
				}
			},
			key: {
				get() {
					return this.filters.key;
				},
				set(val) {
					if(val == null || val == "") {
						this.filters.key = ""
						return
					}

					var re = new RegExp("[a-zA-Z0-9]{1,}-[a-z0-9]{1,}");
					if(re.test(val)) {
						this.filters.key = val
					}
				}
			}
		},
		methods: {
			...mapActions('formulaires', [
				"searchJiraDemandes"
			]),
			...mapMutations('formulaires', [
				"setNotif",
				"setNotifMsg"
			]),
			...mapMutations('global', [
				"sendSuccessNotif"
			]),
			loadData: function() {
				var q = {
					startAt: (this.options.page == 0) ? 0 : (this.options.page - 1) * this.options.itemsPerPage,
					maxResults: this.options.itemsPerPage,
					filters: this.filters
				}

				var _this = this;
				this.loading = true
				this.searchJiraDemandes(q)
				.then(r => {
					_this.demandes = r.demandes;
					_this.total = r.total;
					_this.filters_opt.reporter = r.users.map(x => {
						return {
							id: x.emails.join(","), 
							label: x.fullName
						}
					}).sort((a, b) => {
						if(a.label < b.label) { return -1; }
						if(a.label > b.label) { return 1; }
						return 0;
					})
				})
				.finally(() => {
					_this.loading = false;					
				})
			},
			getColor(status) {
				if (status == "Termine") return 'success'
				if (status == "Ouvert") return 'info'
				else return 'secondary'
			},
			IntervalDateChanged(e) {
				this.filters.created = e;
			},
			changeOptionJira(val) {
				this.headers = val.map(x => {
					return x.item
				});

				localStorage.setItem("jira-data-columns", JSON.stringify(this.headers))				
				this.fields_panel_open = false;
			}
		},
		watch: {
			options: {
				handler (_new, _old) {
					var diffPage 		= _new.page != _old.page;
					var diffNumByPage 	= _new.itemsPerPage != _old.itemsPerPage;

					if(diffPage || diffNumByPage) {
						this.loadData();
					}					
				},
				deep: true,
			},
			filters: {
				handler () {
					this.options.page = 1;
					this.loadData();
				},
				deep: true,
			}
		}
	};
</script>

<style lang="scss">
	.formulaire__jira {
		width: 90%;
		border-radius: 16px;
		margin-top: 30px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		overflow: display;
		padding-bottom: 30px;

		.back-menu
		{
			color: black;
			text-decoration: none;
		}

		h2 {
			width: auto;
			display:inline-block;
			text-transform: uppercase;
			border-bottom:4px solid rgb(253, 190, 69);
		}

		.containeur_demandes
		{
			.field-important
			{
				font-weight: bold;
			}

			.field-mineur
			{
				color:#545454;				
			}
		}

		.input-filter
		{

		}
	}


	@media only screen and (min-width: 701px) and (max-width: 800px) {
		.formulaire__jira
		{
			width: 50%;
		}
	}

	@media only screen and (min-width: 420px) and (max-width: 700px) {
		.formulaire__jira
		{
			width: 80%;
		}
	}

	@media only screen and (min-width: 0px) and (max-width: 419px) {
		.formulaire__jira
		{
			width: 90%;
		}
	}
</style>