<template>
	<div class="InputIntervalDate">
		<v-menu
			ref="menu"
			v-model="menu"
			:close-on-content-click="false"
			transition="scale-transition"
			offset-y
			min-width="auto"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-text-field
					v-model="dateRangeText"
					prepend-icon="mdi-calendar"
					readonly
					v-bind="attrs"
					v-on="on"
					dense
					filled
					clearable
					class="mb-0 pb-0 mt-0 pt-0 input-text"
					@click:clear="clearValue"
				></v-text-field>
			</template>
			<v-date-picker
				v-model="dates"
				no-title
				scrollable
				range
			>
				<v-spacer></v-spacer>
				<v-btn text color="primary" @click="menu = false">Cancel</v-btn>
				<v-btn text color="primary" :disabled="dates.length != 2" @click="save()">OK</v-btn>
			</v-date-picker>
		</v-menu>
	</div>
</template>

<script>
	export default {
		name: 'InputIntervalDate',
		data: () => ({
			dates: [],
			menu: false
		}),
		computed: {
			dateRangeText: {
				get() {
					return this.dates.join(' ~ ');
				},
				set() {
					return
				}
			}
		},
		methods: {
			save: function() {
				this.menu = false;
				this.$emit('change', this.dates);
			},
			clearValue: function() {
				this.dates = [];
				this.$emit('change', this.dates);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.input-text
	{
		font-weight: normal;
		font-size: 12px;
	}
</style>