<template>
	<div class="pa-10 OptionsJiraTab">
		<v-row class="layout">
			<v-col>
				<h3 class="mb-4">Champs disponibles</h3>
				<draggable
					class="col_layout" 
					tag="ul" 
					v-model="list" 
					v-bind="dragOptions" 
					:move="onMove" 
					@start="isDragging=true" 
					@end="isDragging=false"
				>					
					<transition-group type="transition" :name="'flip-list'" style="min-height: 300px;display: inline-block;width:100%">
						<li 
							class="item" 
							v-for="element in list" 
							:key="element.order"
						>
							{{ element.item.text }}
						</li>
					</transition-group>
				</draggable>
			</v-col>
			<v-col>
				<h3 class="mb-4">Champs affichés</h3>
				<draggable element="span" v-model="list2" v-bind="dragOptions" :move="onMove">
					<transition-group name="no" class="col_layout target" tag="ul">
						<li 
							class="item" 
							v-for="element in list2" 
							:key="element.order"
						>
							{{ element.item.text }}
						</li>
					</transition-group>
				</draggable>
			</v-col>
		</v-row>
		<v-btn class="mt-8" color="success" @click="save">
			Sauvegarder
		</v-btn>
	</div>
</template>

<script>
	import draggable from 'vuedraggable'

	export default {
		name: 'OptionsJiraTab',
		props: ['headers_dispo', 'headers'],
		components : {
			draggable
		},
		data: () => ({
			list: [],
			list2: [],
			editable: true,
			isDragging: false,
			delayedDragging: false,
			myArray: []
		}),
		mounted: function(){
			this.list2 = this.headers
				.map((item, index) => {
					return { item, order: index + 1, fixed: false };
				});

			var header_deja_present = this.headers
				.map(x => {
					return x.text
				})

			this.list = this.headers_dispo
				.filter(x => {
					return !header_deja_present.includes(x.text)
				})
				.map((item, index) => {
					return { item, order: index + 100 + 1, fixed: false };
				});
		},
		methods: {
			orderList() {
				this.list = this.list.sort((one, two) => {
					return one.order - two.order;
				});
			},
			onMove({ relatedContext, draggedContext }) {
				const relatedElement = relatedContext.element;
				const draggedElement = draggedContext.element;
				return (
					(!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
				);
			},
			save() {
				this.$emit('change', this.list2)
			}
		},
		computed: {
			dragOptions() {
				return {
					animation: 0,
					group: "description",
					disabled: !this.editable,
					ghostClass: "ghost"
				};
			},
			listString() {
				return JSON.stringify(this.list, null, 2);
			},
			list2String() {
				return JSON.stringify(this.list2, null, 2);
			}
		},
		watch: {
			isDragging(newValue) {
				if (newValue) {
					this.delayedDragging = true;
					return;
				}
				
				this.$nextTick(() => {
					this.delayedDragging = false;
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.OptionsJiraTab
	{
		.col_layout
		{
			border: 2px dashed #d1d1d1;
			min-height: 400px;
			margin:0px;
			padding:0px;

			&:hover
			{
				border: 2px dashed #505050;
			}

			.item
			{
				padding:15px;
				border-bottom: 1px solid #d1d1d1;
				list-style-type: none;
			}
		}
	}
</style>